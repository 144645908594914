<template>
    <div class="text-cell">
        <Editor
            class="editor" 
            @pickFile="startPicking" 
            :filePicker="true"
            :filePicked="filePicked" 
            @change="$emit('change',{text: $event})" 
            :initialValue="data.text" 
            :customParams="editorParams"
        />
        <teleport to="#app">
            <FilePicker :opened="pickFile" @close="stopPicking" @pickedFile="pickedFile" />
        </teleport>
    </div>
</template>

<script>
// import Editor from '@common/components/base/Editor';
import { defineAsyncComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';
import Editor from '@common/components/base/Editor';

export default {
    name: "TextCellAdmin",
    components: {
        Editor,
        FilePicker: defineAsyncComponent(() => import(/* webpackChunkName: "file-picker" */ '@admin/components/cms/file/FilePicker')),
    },
    props: {
        data: Object,
        // tinyID: {
        //     type: String,
        //     required: true,
        // }
    },
    emits: [
        "change"
    ],
    setup(){
        const pickFile = ref(false);
        const filePicked = ref(null);
        const startPicking = () => {
            pickFile.value = true;
            filePicked.value = null;
        };
        const stopPicking = () => {
            pickFile.value = false;
        };

        const pickedFile = (data) => {
            filePicked.value = {
                title: data.url,
                data: {
                    width: '100%',
                    height: '100%',
                    // 'data-src': data.url,
                    // loading: 'lazy'
                }
            };
            stopPicking();
        };

        const store = useStore();

        const editorParams = computed(() => {
            let getList = (type) => store.getters['project/getClassList'](type);
            let params = {
                inline: true,
                image_class_list: getList('image'),
                table_class_list: getList('table'),
                link_class_list: getList('link'),
                table_cell_class_list: getList('table_cell'),
                table_row_class_list: getList('table_row'),
                setup: (editor) => {
                    editor.on('NodeChange', function(e) {
                        if(e.element.tagName === 'IMG'){
                            e.element.setAttribute('loading','lazy');
                            e.element.setAttribute('data-src',e.element.getAttribute('src'));
                        }
                    })
                }
            };

            return params;
        })


        return {
            filePicked,
            pickedFile,
            stopPicking,
            startPicking,
            pickFile,
            editorParams,
        };
    }
}
</script>

<style scoped>
.editor{
    min-height: 10rem;
    /* outline: none; */
    border: 1px dotted gray;
    padding: 1rem;
    margin: 0.5rem;
}

.editor ::v-deep(img){
    max-width: 100%;
}
</style>