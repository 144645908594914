<template>
    <TinyMceEditor 
        :init="params"
        :initial-value="initialValue"
        @change="changeValue"
        @init="loaded"
    />
</template>

<script>
import TinyMceEditor from '@tinymce/tinymce-vue'
import { computed, ref, watch } from 'vue'

// Import TinyMCE
import  '../../tinymce/tinymce.min.js';

// Default icons are required for TinyMCE 5.3 or above
import '../../tinymce/icons/default/icons.min.js';

// A theme is also required
import '../../tinymce/themes/silver/theme.min.js';

// skin
import '../../tinymce/skins/ui/oxide/skin.min.css';
import '../../tinymce/skins/ui/oxide/content.inline.min.css';

// plugins
import '../../tinymce/plugins/preview/plugin.min.js';
import '../../tinymce/plugins/paste/plugin.min.js';
import '../../tinymce/plugins/searchreplace/plugin.min.js';
import '../../tinymce/plugins/autolink/plugin.min.js';
import '../../tinymce/plugins/autosave/plugin.min.js';
import '../../tinymce/plugins/save/plugin.min.js';
import '../../tinymce/plugins/directionality/plugin.min.js';
import '../../tinymce/plugins/code/plugin.min.js';
import '../../tinymce/plugins/visualblocks/plugin.min.js';
import '../../tinymce/plugins/visualchars/plugin.min.js';
import '../../tinymce/plugins/fullscreen/plugin.min.js';
import '../../tinymce/plugins/image/plugin.min.js';
import '../../tinymce/plugins/link/plugin.min.js';
import '../../tinymce/plugins/media/plugin.min.js';
import '../../tinymce/plugins/codesample/plugin.min.js';
import '../../tinymce/plugins/table/plugin.min.js';
import '../../tinymce/plugins/charmap/plugin.min.js';
import '../../tinymce/plugins/hr/plugin.min.js';
import '../../tinymce/plugins/anchor/plugin.min.js';
import '../../tinymce/plugins/toc/plugin.min.js';
import '../../tinymce/plugins/advlist/plugin.min.js';
import '../../tinymce/plugins/lists/plugin.min.js';
import '../../tinymce/plugins/wordcount/plugin.min.js';
import '../../tinymce/plugins/imagetools/plugin.min.js';
import '../../tinymce/plugins/textpattern/plugin.min.js';
import '../../tinymce/plugins/noneditable/plugin.min.js';
import '../../tinymce/plugins/quickbars/plugin.min.js';

export default {
    name: "Editor",
    components: {
        TinyMceEditor
    },
    props: {
        variant: {
            type: String,
            default: () => 'full',
        },
        initialValue: {
            type: String,
            default: () => '',
        },
        customParams: {
            type: Object,
            default: () => {}
        },
        filePicker: {
            type: Boolean,
            default: () => false,
        },
        filePicked: {
            type: Object,
            default: () => null
        },
    },
    emits: [
        "pickFile",'change'
    ],
    setup(props,context) {
        const variantSpecific = computed(() => {
            let variant = {};
            switch(props.variant) 
            {
                case 'email':
                    variant.toolbar = 'undo redo | bold italic underline strikethrough | code | pastetext image link | fontsizeselect| alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | charmap |  codesample | ltr rtl | fullscreen visualblocks preview';
                    variant.plugins = 'preview paste searchreplace autolink directionality code visualblocks visualchars fullscreen image link codesample table charmap hr toc advlist lists wordcount imagetools textpattern noneditable quickbars';
                    break;
                case 'lite':
                    variant.toolbar = 'undo redo | bold italic underline strikethrough  | pastetext | alignleft aligncenter alignright alignjustify | outdent indent | removeformat | ltr rtl | visualblocks code';
                    variant.plugins = 'preview paste searchreplace autolink directionality code visualblocks visualchars fullscreen image link codesample table charmap hr toc advlist lists wordcount imagetools textpattern noneditable quickbars';
                    break;
                case 'full':
                default:
                    variant.toolbar = 'undo redo | bold italic underline strikethrough | code | pastetext image media link | styleselect fontsizeselect removeformat | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor | table charmap  | anchor codesample | ltr rtl | fullscreen visualblocks preview';
                    variant.plugins = 'preview paste searchreplace autolink directionality code visualblocks visualchars fullscreen image link media codesample table charmap hr anchor toc advlist lists wordcount imagetools textpattern noneditable quickbars';
                   break;
            }
            
            switch(props.variant)
            {
                case 'lite':
                    variant.menubar = false;
                    variant.quickbars_selection_toolbar = false;
                    variant.valid_elements = 'strong,em,p[style|dir],span[style|dir],br,div[style|dir]';
                    variant.extended_valid_elements = false;
                    break;
                case 'full':
                default:
                    variant.menubar = 'file edit insert view format table tools';
                    variant.quickbars_selection_toolbar = "bold italic | link h2 h3 blockquote";
                    variant.valid_elements = false;
                    variant.extended_valid_elements = 'b,li[*],i[*],svg[*],defs[*],pattern[*],desc[*],metadata[*],g[*],mask[*],path[*],line[*],marker[*],rect[*],circle[*],ellipse[*],polygon[*],polyline[*],linearGradient[*],radialGradient[*],stop[*],image[*],view[*],text[*],textPath[*],title[*],tspan[*],glyph[*],symbol[*],switch[*],use[*]';
                    break;
            }
            if(props.filePicker){
                variant.file_picker_callback = (callback) => {
                    context.emit('pickFile');
                    let timer = setInterval(() => {
                        if(props.filePicked){
                            clearInterval(timer);
                            callback(props.filePicked.title,props.filePicked.data)
                        }
                    },500);
                    
                    // (callback, value, meta)
                    // if (meta.filetype == 'image') {
                    // }
                }
            }
            return variant;
        });
        
        
        

        const defaultParams = {
                height: 600,
                skin_url: '/tinymce/skins/ui/oxide',
                //content_css: false,
                 content_css: '/tinymce/skins/ui/oxide/content.inline.min.css', 
                language: "cs",
                language_url: `/tinymce/langs/cs.js`,
                entity_encoding : "raw", 
                toolbar_sticky: true,
                fontsize_formats: '0.688rem 0.75rem 0.875rem 1rem 1.125rem 1.5rem 2.25rem 3rem' ,
                image_advtab: true,
                toolbar_mode: 'floating',
                block_formats: 'Odstavec=p; Nadpis 1=h1; Nadpis 2=h2; Nadpis 3=h3; Nadpis 4=h4; Nadpis 5=h5; Nadpis 6=h6; Předformátovaný text=pre; Div=div',
                imagetools_toolbar: "imageoptions",
                contextmenu: "link image imagetools table",
                menu: {
                    file: { title: 'File', items: 'restoredraft | preview ' },
                    format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | blockformats fontsizes align | forecolor backcolor | removeformat' },
                },
                image_caption: false,
                quickbars_insert_toolbar: false,
                formats: {
                    bold: [
                        { inline: 'strong', remove: 'all' },
                        { inline: 'span', styles: { fontWeight: 'bold' } },
                        { inline: 'b', remove: 'all' },
                    ],
                    italic:  [
                        { inline: 'em', remove: 'all' },
                        { inline: 'span', styles: { fontWeight: 'italic' } },
                        { inline: 'i', remove: 'all' },
                    ],
                },
                relative_paths : true,
                remove_script_host : false,
                convert_urls : false,
                table_sizing_mode: 'responsive',
                style_formats: [
                    { title: 'Headings', items: [
                        { title: 'Nadpis [H1]', format: 'h1' },
                        { title: 'Nadpis [H2]', format: 'h2' },
                        { title: 'Nadpis [H3]', format: 'h3' },
                        { title: 'Nadpis [H4]', format: 'h4' },
                        { title: 'Nadpis [H5]', format: 'h5' },
                        { title: 'Nadpis [H6]', format: 'h6' }
                    ]},
                    { title: 'Formát', items: [
                        { title: 'Odstavec [p]', format: 'p' },
                        { title: 'Odstavec [div]', format: 'div' },
                        { title: 'Odstavec [pre]', format: 'pre' },
                        { title: 'Blockquote', format: 'blockquote' }
                    ]}
                ],  
               codesample_languages: [
                    { text: 'HTML/XML', value: 'markup' },
                    { text: 'JavaScript', value: 'javascript' },
                    { text: 'CSS', value: 'css' },
                    { text: 'PHP', value: 'php' }
                ],
                 content_style: "body {font-size: 1rem;}"
            };
        
        const variantParams = computed(() => {
            return Object.assign(defaultParams, variantSpecific.value)
        })

        const params = computed(() => {
            return Object.assign(variantParams.value, props.customParams)
        })  
        
        const changeValue = (event,editor) => {
            context.emit('change',editor.getContent());
            // console.log(editor.getContent());
        }


        const thisEditor = ref(null);
        const loaded = (event, editor) => {
            thisEditor.value = editor;
        }
        watch(() => props.initialValue, () => {
            if(!thisEditor.value || tinymce.activeEditor.id === thisEditor.value.id){
                return;
            }
            thisEditor.value.setContent(props.initialValue);
        });

        return {
            params,
            changeValue,
            loaded
        }
    }
}
</script>

<style scoped>

</style>